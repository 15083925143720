import React from 'react'
import IconTitle from '../../components/IconTitle'

const Puppets = () => {
  return (
    <div>
      <IconTitle title="Historias musicales con Marionetas / Musicoterapia para menores." />
      {/* Primer elemento: imagen y texto lado a lado */}
      <div className="md:grid md:grid-cols-2 gap-4 items-center mb-4">
        <div className="flex flex-col items-center">
          <img
            src="/images/patrice.png"
            className="w-full max-w-[400px] object-contain mt-4 mb-4"
            alt="Artístico"
          />
        </div>
        <div className="flex flex-col items-center">
          <img
            src="/images/marionetas.png"
            className="w-full max-w-[400px] object-contain mt-4 mb-4"
            alt="Artístico"
          />
        </div>
      </div>
      <div className="text-base space-y-4">
        <p className="text-justify">
          Actividad de ocio musical para disfrute de todos los públicos, especialmente
          Infantil. Show Interactivo: Con Canciones, Cuento, Música y Teatro. Junto al cantante
          y actor Patrice Triscol. En Lenguaje Castellano, Inglés y Francés.
        </p>
      </div>
    </div>
  )
}

export default Puppets
